import * as React from 'react'
import cn from 'classnames'
import { InputProps, PropsValue, SingleValue, components } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

const isSingle = (
  value: PropsValue<PositionSelectOption>
): value is SingleValue<PositionSelectOption> => !Array.isArray(value)

export const Input = (props: InputProps<PositionSelectOption, false>) => {
  const { inputClassName, isMulti } = props

  const value = isSingle(props.selectProps.value)
    ? props.selectProps.value?.label
    : props.selectProps.value.map((v) => v.label).join(', ')
  const hasValue = !!value?.length

  const showSeparator = hasValue && !props.selectProps.inputValue

  return (
    <div
      style={{ gridArea: '1/1/2/3' }}
      className={cn(inputClassName, 'relative text-red-500')}
    >
      {showSeparator && isMulti && (
        <span className="absolute top-1/2 -translate-y-1/2 text-gray-400 cursor-text">
          and...
        </span>
      )}
      <components.Input
        {...props}
        className="text-sm ml-0"
        inputClassName="shadow-none"
        aria-valuetext={value}
      />
    </div>
  )
}

import { components, GroupProps } from 'react-select'
import * as React from 'react'
import cn from 'classnames'
import { PositionSelectOption } from '../position-select.types'

export const Group = (props: GroupProps<PositionSelectOption, false>) => {
  const { className, ...restProps } = props

  return (
    <components.Group
      {...restProps}
      className={cn(
        'border-0 border-b border-gray-100 border-solid pb-0 pt-2 last-of-type:border-0',
        className
      )}
    />
  )
}

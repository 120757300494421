import * as React from 'react'
import { IndicatorsContainerProps, components } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

export const IndicatorsContainer = (
  props: IndicatorsContainerProps<PositionSelectOption, false>
) => (
  <components.IndicatorsContainer
    {...props}
    className="mt-1 h-8 flex items-center cursor-pointer"
  />
)

import { Tag } from 'src/design-system'
import * as React from 'react'
import { components, SingleValueProps } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

export const SingleValue = (
  props: SingleValueProps<PositionSelectOption, false>
) => {
  const {
    data: { label, positionCode },
  } = props

  return (
    <components.SingleValue
      {...props}
      className="flex flex-row items-center gap-x-1"
    >
      <Tag className="mr-2 py-0.5" variant="paper">
        {positionCode}
      </Tag>
      {label}
    </components.SingleValue>
  )
}

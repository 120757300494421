import { Position } from 'store/modules/positions'
import { PositionSelectOption } from './position-select.types'
import { sortAlphaNumberValues } from '../../utils/sort-helpers'
import { Team } from 'store/modules/teams'

export type AggregatedPositionOption = {
  isDisabled: boolean
  positionCode: string
  positionName: string
  value: string
}

export class PositionSelectVm {
  constructor(
    private positions: Position[],
    private disablePositionsWithNoRequirements: boolean,
    private defaultValueId?: string | null
  ) {}

  private get sortedTeams(): Team[] {
    const teams = this.positions.reduce((acc, position) => {
      if (position.team && !acc.find((team) => team.id === position.team.id)) {
        acc.push(position.team)
      }
      return acc
    }, [] as Team[])

    return teams.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  get options() {
    const options: Record<string, AggregatedPositionOption[]> = {}

    this.sortedTeams.forEach((team) => {
      const teamPositions = this.positions.filter(
        (position) => position.team && position.team.id === team.id
      )

      teamPositions.forEach((position) => {
        position.disciplines.forEach((discipline) => {
          if (!discipline) return
          options[team.name] = options[team.name] || []

          options[team.name].push({
            isDisabled:
              this.disablePositionsWithNoRequirements &&
              position.requirementsCount === 0,
            positionCode: position.positionCode(discipline.initials),
            positionName: position.name,
            value: position.id,
          })
        })
      })
    })

    return Object.entries(options).map(([groupName, options]) => ({
      label: groupName,
      options: options
        .sort((a, b) => sortAlphaNumberValues(a.positionCode, b.positionCode))
        .map((option) => {
          return {
            isDisabled: option.isDisabled,
            label: option.positionName,
            positionCode: option.positionCode,
            value: option.value,
          }
        }),
    }))
  }

  get defaultPositionOption(): PositionSelectOption | null {
    let option = null
    if (this.options.length === 0) return null
    if (this.defaultValueId === null || this.defaultValueId === undefined)
      return null

    this.options.forEach((groupOption) => {
      option ||= groupOption.options.find(
        (option) => option.value === this.defaultValueId
      )
    })

    return option || this.options[0].options[0]
  }
}

import * as React from 'react'
import cn from 'classnames'
import { ControlProps, components } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

type CustomControlProps = ControlProps<PositionSelectOption, false> & {
  prefix?: string
  borderless?: boolean
}

export const Control = (props: CustomControlProps) => {
  const { children, prefix, ...restProps } = props

  return (
    <components.Control
      className={cn(
        'flex flex-row items-center rounded px-3 shadow-none text-sm',
        props.borderless
          ? 'border-[transparent] -mx-3'
          : 'border-px border-solid !pl-0 !pr-1 !border-gray-100 h-full',
        !props.isFocused ? 'border-[transparent]' : '!border-theme-60'
      )}
      {...restProps}
    >
      {prefix && (
        <span className="font-bold h-8 self-baseline flex items-center mt-1">
          {prefix}
        </span>
      )}
      {children}
    </components.Control>
  )
}

import * as React from 'react'
import { PlaceholderProps, components } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

export const Placeholder = (
  props: PlaceholderProps<PositionSelectOption, false>
) => (
  <components.Placeholder
    {...props}
    innerProps={{
      className:
        'text-gray-400 relative flex flex-row items-center whitespace-nowrap',
    }}
  />
)

import React from 'react'
import cn from 'classnames'
import Select, { SingleValue } from 'react-select'
import * as Components from './components'
import { PositionSelectVm } from './position-select-vm'
import {
  PositionSelectOption,
  PositionSelectProps,
} from './position-select.types'

export const PositionSelect = (props: PositionSelectProps) => {
  const {
    value,
    onChange,
    menuIsOpen,
    placeholder = 'Position',
    prefix = 'Position',
    name = 'position',
    className,
    positions,
    isDisabled,
    borderless = true,
    required = true,
    isClearable = false,
    disablePositionsWithNoRequirements = true,
    ...restProps
  } = props

  const vm = React.useMemo(() => {
    return new PositionSelectVm(
      positions,
      disablePositionsWithNoRequirements,
      value
    )
  }, [positions, value, disablePositionsWithNoRequirements])

  React.useEffect(() => {
    setSelectedOption(vm.defaultPositionOption)
  }, [vm])

  const [selectedOption, setSelectedOption] = React.useState(
    vm.defaultPositionOption
  )

  return (
    <div data-testid="position-select-input" className="contents">
      <Select
        {...restProps}
        menuIsOpen={menuIsOpen}
        className={cn('position-select', className)}
        onChange={(newValue: SingleValue<PositionSelectOption>) => {
          if (newValue) {
            onChange?.(newValue.value)
            setSelectedOption(newValue)
          } else {
            onChange?.(null)
            setSelectedOption(null)
          }
        }}
        value={selectedOption}
        name={name}
        inputId={name}
        options={vm.options}
        closeMenuOnSelect
        blurInputOnSelect
        isMulti={false}
        required={required}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isClearable={isClearable}
        components={{
          ...Components,
          Control: (props) => (
            <Components.Control
              prefix={prefix}
              borderless={borderless}
              {...props}
            />
          ),
        }}
      />
    </div>
  )
}

import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ClearIndicatorProps, components } from 'react-select'
import { PositionSelectOption } from '../position-select.types'

export const ClearIndicator = (
  props: ClearIndicatorProps<PositionSelectOption, false>
) => (
  <components.ClearIndicator {...props} className="py-0">
    <X weight="bold" className="w-4 h-4 text-gray-600" aria-hidden />
    <span className="sr-only">Clear</span>
  </components.ClearIndicator>
)

import * as React from 'react'
import cn from 'classnames'
import { components, OptionProps } from 'react-select'
import { PositionSelectOption } from '../position-select.types'
import { Tag } from 'src/design-system'

export const Option = (props: OptionProps<PositionSelectOption, false>) => {
  const { data, isFocused, isSelected, label, isDisabled } = props

  const { positionCode } = data

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-transparent',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900',
        isDisabled && '!text-gray-300 hover:cursor-default hover:!bg-white'
      )}
    >
      <div className="flex items-center flex-nowrap" data-selected={isSelected}>
        <Tag className="mr-2 py-0.5 bg-gray-100" variant="paper">
          {positionCode}
        </Tag>
        {label}
      </div>
    </components.Option>
  )
}
